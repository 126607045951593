import { z } from 'zod'

// Define the schema for a single row field
const rowFieldSchema = z.object({
  label: z.string(),
  value: z.string(),
  name: z.string(),
  editable: z.boolean(),
})

// Define the schema for handleReturn function output
const handleReturnSchema = z.array(rowFieldSchema)

const colSchema = z.array(
  z.object({
    data: handleReturnSchema,
  })
)

// Define the schema for the children of tabularData
// const childrenSchema = z.array(
//   z.object({
//     rowTitle: z.string(),
//     columns: colSchema,
//   })
// )
const childrenSchema: z.ZodType<unknown> = z.lazy(() =>
  z.array(
    z.object({
      rowTitle: z.string().optional(),
      title: z.string().optional(),
      columns: colSchema.optional(),
      children: childrenSchema.optional(), // Allow nested children
    })
  )
)

// Define the schema for tabularData
///grouped rows amd columns
export const tabularDataSchema = z.array(
  z.object({
    title: z.string(),
    id: z.string(),
    grouped: z.boolean(),
    children: childrenSchema,
    editable: z.boolean(),
  })
)
//single Row
// export const tabularDataSchema = z.array(
//   z.object({
//     title: z.string(),
//     id: z.string().optional(),
//     grouped: z.boolean(),
//     children: handleReturnSchema,
//   })
// )
export type TabualrDataSchema = z.infer<typeof tabularDataSchema>
